import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import React, { Component } from 'react';


import NavigationBar from './components/Nav/Nav';
// import Home from './components/Home/Home';
// import Order from './components/Order/Order';
// import Footer from './components/Footer/Footer';
// import ThankYou from './components/Thank-You/Thank-You';
// import NotFound from './components/NotFound/NotFound';
// import FAQ from './components/FAQ/FAQ';

// const NavigationBar = React.lazy(() => import('./components/Nav/Nav'));
const Home = React.lazy(() => import('./components/Home/Home'));
const Order = React.lazy(() => import('./components/Order/Order'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const ThankYou = React.lazy(() => import('./components/Thank-You/Thank-You'));
const NotFound = React.lazy(() => import('./components/NotFound/NotFound'));
const FAQ = React.lazy(() => import('./components/FAQ/FAQ'));


class App extends Component {
  
  render()
  {

    var linkToHome = "#home";
    var linktoAboutUs = "#our-story";

    if (window.location.pathname !== "/")
    {
      linkToHome = "/#home";
      linktoAboutUs = "/#our-story";
    }

  return (
    <div className="App">
    <NavigationBar linkToHome={linkToHome} linktoAboutUs={linktoAboutUs}/>
    <React.Suspense fallback={<span>Loading...</span>}>
    <Router>
      <Switch>
          
          <Route path="/" exact render={() =>
            <Home/>
          }/>  

          <Route path="/order" exact render={() =>
            <Order/>
          }/>            
          
          <Route path="/faq" exact render={() =>
            <FAQ/>
          }/>             

          <Route path="/thank-you" exact render={(props) =>
            <ThankYou {...props}/>
          }/>        
          
          <Route render ={() => 
           <NotFound/>
          }/>  
        </Switch>
        <Footer/>
        
    </Router>
    </React.Suspense>
    </div>
  );

        }
}

export default App;
