import './Nav.css';
import { Component } from 'react';
import  Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import logo from './logo_c.webp';



class NavigationBar extends Component{
    
    
    componentDidMount = () =>
    {
        var navbar = document.getElementById("navigationbar");
        var logoMobile = document.getElementById("logo-mobile");
        window.onscroll = function() {
            if (window.innerWidth > 991)
            {
            var offset = document.getElementById("logo-nomobile").offsetHeight;
            
            if (window.pageYOffset >= offset)
            {
                navbar.classList.add("sticky");

            }
            else
            {
                navbar.classList.remove("sticky");
            }
        }
        else
        {
            
            if (window.pageYOffset > 10)
            {  
                logoMobile.classList.add("logo-scrolled");
            }
            else
            {
                logoMobile.classList.remove("logo-scrolled");
            }
        }
        };
    }

    render()
    {
    return (

        <div className="navigation-bar">    
        <div className="logo-nomobile" id="logo-nomobile">
        <img src={logo} className="logo" alt="brand-logo"/>
        </div>
        <Navbar id="navigationbar" className="custom-navbar fixed-mobile" collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand className="mx-auto">
                <img src={logo} className="logo" id="logo-mobile" alt="brand-logo"/>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">    
                <Nav className="mx-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href={this.props.linktoAboutUs}>Our Story</Nav.Link>
                    <Nav.Link href="#contact-us">Contact Us</Nav.Link>
                    <Nav.Link href="faq">FAQ</Nav.Link>
                    <Nav.Link className="order-btn" href="/order">Order</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <div className="empty"></div>
        </div>
        



    //   <div className="container-fluid navbar-container">
    //     <nav className="navbar custom-navbar navbar-expand-md bg-light">
    //         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    //             <span className="fas fa-align-justify"></span>
    //         </button>
    //     <div className="collapse navbar-collapse" id="collapsibleNavbar">
    //         <ul className="navbar-nav mx-auto">
    //             <li className="nav-item">
    //                 <a className="nav-link" href="/" >Home</a>
    //             </li>
    //             <li className="nav-item">
    //                 <a className="nav-link" href="#contact-us" >Contact Us</a>
    //             </li>
    //             <li className="nav-item">
    //                 <a className="nav-link" href={this.props.linktoAboutUs}>About us</a>
    //             </li>
    //             <li className="nav-item order-btn">
    //                 <a className="nav-link" href="/Order">Order</a>
    //             </li>
    //         </ul>
    //     </div>
    //     </nav>
    //     
    // </div>
    );
    }
  }
  
  export default NavigationBar;
  